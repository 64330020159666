@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "questrial";
  src: url(./assets/fonts/Questrial-Regular.ttf);
}

@font-face {
  font-family: "dm-sans";
  src: url(./assets/fonts/DMSans-VariableFont_opszwght.ttf);
}

*.font-questrial {
  font-family: "questrial" !important;
}

body {
  font-family: "questrial";
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
  height: 6px;
}
*::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  background-color: #444;
  border-radius: 1.25rem;
}

*::-webkit-scrollbar-track {
  width: 6px;
  height: 6px;
  background-color: transparent;
  position: relative;
}

.attendance-scrollbar::-webkit-scrollbar-track {
  height: 6px;

  background-color: #999;
}

/* Billing Yearly / Monthly Switch */

.switch {
  width: 40px;
  height: 1.25rem;
  position: relative;
  border-radius: 10px;
  @apply bg-darkGray shadow-sm;
}
.switch::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 1.25rem;
  background-color: white;
  border-radius: 50%;
  transition: 0.3s;
  cursor: pointer;
}

.switch.toggled::after {
  transform: translateX(100%);
}

.input-field {
  @apply w-full outline-none bg-white border-[1.5px] focus:border-black rounded-md text-black placeholder:text-darkGray px-3 py-2;
}

.radio {
  @apply w-[1.25rem] h-[1.25rem] border-[1.5px] border-black rounded-full flex justify-center items-center relative after:absolute after:top-[50%] after:left-[50%] after:-translate-x-[50%] after:-translate-y-[50%] after:z-[3] after:w-[10px] after:h-[10px] after:bg-white after:rounded-full;
}

.radio.checked {
  @apply after:bg-black;
}

/* svgs for progress container */

svg.progress-bar {
  width: 200px;
  height: 200px;
  transform: rotate(-90deg);
  overflow: initial;
}

.progress-bar circle {
  stroke-width: 10px;
  fill: none;
}

.progress-bar circle:nth-child(1) {
  stroke: #5151f536;
}
.progress-bar circle:nth-child(2) {
  stroke: royalblue;
  position: relative;
  z-index: 1;
}
.circle_box circle:nth-child(2) {
}
.circle_box {
  font-size: 36px;
  @apply text-darkGray;
  text-align: center;
}
.circle_box div {
  position: relative;
  max-width: fit-content;
}
.circle_box span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  @apply text-darkGray;
  font-size: 1.5rem;
}

/* Custom Animations */
.animate-fade {
  animation: fade-up 0.3s linear;
}

.hide-banner > *:not(.no-animation) {
  transition-duration: 500ms;
  opacity: 0 !important;
}

.banner > * {
  transition: all 600ms;
  opacity: 1;
}

@keyframes fade-up {
  0% {
    opacity: 0.3;
    transform: translateY(100%) scale(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(100%);
  }
}
